$(function(){
  $('.kv').slick({
    autoplay: true,
    autoplaySpeed: 4500,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    arrows: true,
    dots: true,
    responsive: [{
      breakpoint: 1200,
      settings: {
        swipe: true,
        }
    },{
      breakpoint: 768,
      settings: {
        arrows: false,
      }
    }]
  });
});
